.header-image-container {
  display: flex;
  align-items: center;
  padding: 20px;
  margin-bottom: 50px;
}

.header-image-container img {
  height: 40px;
}

.header-image-divider {
  height: 50px;
  width: 2px;
  background-color: black;
  margin: 0px 30px;
}

.header-logout-link {
  position: fixed;
  color: #00A1E0;
  top: 30px;
  right: 30px;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}

.header-logout-link:hover {
  color: #0081b4;
}
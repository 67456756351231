.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #ffffff 0%, #98B6C1 25%, #4F7787 50%, #243E49 75%, #000000 100%);
  height: 100vh;
}

.login-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 750px;
  width: 750px;
  background: white;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 10%;
  margin-bottom: 30%;
}

.image-container img {
  height: 40px;
}

.image-divider {
  height: 50px;
  width: 2px;
  background-color: black;
  margin: 0px 30px;
}

.form-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: min-content;
  height: 100px;
}

.form-label{
  margin-bottom: 10px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}



.form-input{
  margin-bottom: 20px;
  font-family: inherit;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  font-size: 1rem;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: #00A1E0;
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 18px;
  width: 150px;
  height: 50px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  padding: 5px;
  margin-top: 20%;
}

.submit:hover {
  background-color: #0086BA;
}

.submit:focus {
  outline: none;
}




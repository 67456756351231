.select-recipients-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.select-recipients-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 24px;
}

.select-recipients-input-container{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-self: center;
  width: 95%;
}

.select-recipients-input{
  margin-left: 10px;
  margin-right: 5px;
}

.select-recipients-input-button{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00A1E0;
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 14px;
  width: 50px;
  height:25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  padding: 5px;
}

.select-recipients-input-button:hover {
  background-color: #0081b4;
}

.recipients-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  align-self: center;
  width: 95%;
}

.recipient-item {
  display: flex;
  align-items: center;
  background-color: #eeeeee;
  height: 10px;
  margin: 10px;
  padding: 5px;
}

.recipient-name {
  flex: 1;
}

.remove-icon {
  cursor: pointer;
  margin-left: 5px;
}

.remove-icon img {
  width: 10px;
  height: 10px;
}

.select-tables-container{
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.select-tables-text{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 24px;
}

.checkbox-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
  width: 95%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.checkbox-item {
  margin: 10px;
}
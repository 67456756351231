.container{
    display: flex;
    flex-direction: column;
}

.submit_container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: flex-end;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 30px;
}

.submit_button_save_export_settings{
  background-color: #00A1E0;
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 15px;
  height: 30px;
  width: 200px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  padding: 5px;
}

.submit_button_save_export_settings:hover {
  background-color: #0081b4;
}

.submit_button_export{
  background-color: #e00000;
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 15px;
  width: 100px;
  height: 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  padding: 5px;
  margin-left: 10px;
}

.submit_button_export:hover {
  background-color: #b90000;
}